@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,600,800');
@include mat.core();

@mixin font-face($font-family, $file-path, $format: 'woff2', $weight: normal, $style: normal) {
  @font-face {
    font-family: $font-family;
    src: url('/assets/fonts/' + $file-path) format($format);
    font-display: auto;
  }
}

@layer components {
    .angular-input {
        @apply p-3 bg-angular-input hover:bg-angular-input-h rounded-tl rounded-tr border-b border-b-gray-500 hover:border-b-black;
    }
}

$acg-accent-palette: (
        50 : #e0e0e0,
        100 : #b3b3b3,
        200 : #808080,
        300 : #4d4d4d,
        400 : #262626,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #a6a6a6,
        A200 : #8c8c8c,
        A400 : #737373,
        A700 : #666666,
        contrast: (
                50 : #000000,
                100 : #000000,
                200 : #000000,
                300 : #ffffff,
                400 : #ffffff,
                500 : #ffffff,
                600 : #ffffff,
                700 : #ffffff,
                800 : #ffffff,
                900 : #ffffff,
                A100 : #000000,
                A200 : #000000,
                A400 : #ffffff,
                A700 : #ffffff,
        )
);

$my-primary: mat.define-palette($acg-accent-palette);
$my-accent: mat.define-palette($acg-accent-palette);
$my-font: mat.define-typography-config($font-family: 'Montserrat');

$my-theme: mat.define-light-theme((
        color: (
                primary: $my-primary,
                accent: $my-accent,
        ),
        typography: $my-font,
        density: 0,
));

@include mat.all-component-themes($my-theme);

// Accent plotter fonts
@include font-face("Futura Extra Black Condensed BT", "futuraExtraBlackCondensed.woff2");
@include font-face("Swiss 721 Black Rounded BT", "swiss721BlackRounded.woff2");
@include font-face("Zapf Humanist 601", "zapfHumanist601.woff2");
@include font-face("Zurich Bold Extra Condensed BT", "zurichBoldExtraCondensed.woff2");
@include font-face("Zurich Black Italic BT", "zurichBlackItalic.woff2");
@include font-face("VAG Rounded BT", "VAGRounded.woff2");
@include font-face("Brush Script", "brushScript.woff2");
@include font-face("Dom Casual BT", "domCasual.woff2");
@include font-face("Hobo BT", "hobo.woff2");

// Intersport plotter fonts
@include font-face("FF DIN Pro for IIC", "FFDINProforIIC.ttf", "truetype");
@include font-face("FF DIN Pro for IIC Black", "FFDINProforIIC-Black.ttf", "truetype");
@include font-face("FF DIN Pro for IIC Bold", "FFDINProforIIC-Bold.ttf", "truetype");
@include font-face("FF DIN Pro for IIC Light", "FFDINProforIIC-Light.ttf", "trutype");
@include font-face("FF DIN Pro for IIC Medium", "FFDINProforIIC-Medium.ttf", "truetype");


@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.mat-card:not([class*=mat-elevation-z]) {
  box-shadow: none !important;
}
html, body {
  font-family: 'Montserrat', sans-serif;
}

:root {
  --modal-xl-width: 98%;
  --modal-lg-width: 75%;
  --modal-md-width: 50%;
  --modal-sm-width: 30%;
  --modal-sm-height-width: 40%;
}

.modal-xl {
  min-width: var(--modal-xl-width);
}

.modal-lg {
  min-width: var(--modal-lg-width);
}
.modal-md {
  min-width: var(--modal-md-width);
}

.modal-sm {
  min-width: var(--modal-sm-width) !important;
  margin: 1.75rem auto;
}

.modal-sm-height {
  min-width: var(--modal-sm-height-width) !important;
  overflow: hidden;
}

.mdc-tooltip {
    white-space: pre-line !important;
}

.select-centered-items .mdc-list-item__primary-text {
    width: 100%!important;
    height: 100%!important;
}
